import React, {
    useEffect,
    useContext
} from "react";

import { useLocation } from "react-router-dom";

import { UserContext } from "../App";
// import homeData from "../rawData/HomePageData";

class getCount {
    static count = 0;
    static increament(index) {
        this.count = index;
    }
}

function ShowRecentDev() {
    let data = useLocation();
    const preDefinedFunc = useContext(UserContext);
    document.title = "CMD | Recent Developments"
    useEffect(() => {
        const animationContainer = document.querySelectorAll(".show-description-heading, .show-description-summary, .showProduct-secondary-image, .work-display-item");
        const observeNewDivFunc = (element, value, thresholdValue) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
            observer.observe(element);
        }
        animationContainer.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
    }, [preDefinedFunc]);

    const showcaseImages = (sourceTarget, index) => {
        const main_container = document.querySelector(".main_container");
        const displayContainer = document.querySelector(".display_image-container");
        const header_conatiner = document.querySelector(".cmd-brand-container");
        displayContainer.style.setProperty("top", `calc(${main_container.scrollTop - header_conatiner.clientHeight}px)`);
        displayContainer.classList.add("triggerDisplay");
        main_container.style.overflowY = "hidden";
        document.querySelector(".display-image").src = sourceTarget;
        getCount.increament(index);
    }
    const cancelShowDisplayImages = () => {
        const displayContainer = document.querySelector(".display_image-container");
        const mainContainer = document.querySelector(".main_container");
        displayContainer.classList.remove("triggerDisplay");
        displayContainer.style.top = 0;
        mainContainer.style.overflowY = "scroll";

    }

    const leftImageHandler = () => {
        if (0 <= (getCount.count - 1)) {
            document.querySelector(".display-image").src = data.state.data.recentDev_inner_images[getCount.count - 1];
            getCount.increament(getCount.count - 1);
        }
    }

    const rightImageHandler = () => {
        if ((getCount.count + 1) < data.state.data.recentDev_inner_images.length) {
            document.querySelector(".display-image").src = data.state.data.recentDev_inner_images[getCount.count + 1];
            getCount.increament(getCount.count + 1);
        }
    }
    return (
        <div className="showProduct_page work-display display-margin-top-5rem">
            <div className="showProduct_page-header work-display-header">
                <img className="showProduct_header-img work-display-header-img" src={data.state.data.recentDev_header_image} alt="showProduct_header_image" />
                <div className="gradient gradient-more"></div>
                <div className="showProduct_header-text work-display-header-text">
                    <div className="showProduct_header-heading work-display_header-heading letter-spacing-2px">
                        RECENT DEVELOPMENTS
                    </div>
                    <div className="showProduct_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">
                        {data.state.data.recentDev_heading}
                    </div>
                    {/* <div className="showProduct_header-sub-heading2 work-display_header-sub-heading2">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit pariatur debitis officia sint voluptate veniam dignissimos quidem nesciunt hic non?
                    </div> */}
                </div>
                {/* <div className="productNavigateBtn left-btn">
                    <div className='nav-btn-box1'>
                        <img src="/image/img2.jpg" alt="product_image" className='left-btn-image' />
                    </div>
                    <div className='nav-btn-box2'>
                        <i className="fa-solid fa-chevron-right btn-right"></i>
                    </div>
                    <span className='btn-title'>
                        <p className='productTitle'>Planetarium</p>
                    </span>
                </div> */}
                {/* <div className="productNavigateBtn right-btn" >
                    <div className='nav-btn-box2'>
                        <i className="fa-solid fa-chevron-left btn-right"></i>
                    </div>
                    <div className='nav-btn-box1 nav-btn-box1-right'>
                        <img src="/image/img2.jpg" className='right-btn-image' alt="product_image" />
                    </div>
                    <span className='btn-title'>
                        <p className='productTitle'>Planetarium</p>
                    </span>
                </div> */}
            </div>
            <div className="showProduct-container show-description">
                <div className="showProduct-heading show-description-heading">
                    <h1 className="letter-spacing-1px">ABOUT RECENT DEVELOPMENTS</h1>
                </div>
                <div className="showProduct-summary showProject-summary show-description-summary">
                    <p>{data.state.data.recentDev_heading}</p>
                    <p><strong>LOCATION:</strong> {data.state.data.recentDev_location}</p>
                    <p className="" dangerouslySetInnerHTML={{ __html: data.state.data.recentDev_summary }}></p>
                </div>
            </div>
            <div className="project_page-content work-display-content">
                <div className="project-item-grid show-projects-item work-display-item-grid padding-less">
                    {
                        data.state.data.recentDev_inner_images.map((image, index) => {
                            return (
                                <div className="project-item work-display-item" key={index} onClick={() => showcaseImages(image, index)} >
                                    <img src={image} alt="dpr_item_image" />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="display_image-container">
                <div className="left-bar" onClick={leftImageHandler}>
                    <i className="fa-solid fa-chevron-left btn"></i>
                </div>
                <div className='imageScreen'>
                    <img className='display-image' src="/media/projects_data/project_first_header.jpg" alt="justImage" />
                </div>
                <div className="right-bar" onClick={rightImageHandler}>
                    <i className="fa-solid fa-chevron-right btn" ></i>
                </div>
                <div className="close-bar btn" onClick={cancelShowDisplayImages}>
                    <span className='cancelBtn'></span>
                </div>
            </div>
        </div>

    )
}

export default React.memo(ShowRecentDev);